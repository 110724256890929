.thank-you {
    display: flex;
    flex-direction: column;

    .title {
        font-size: 17px;
        font-weight: 500;
        margin-bottom: 8px;
        color: #fff;
        margin-top: 30px;
        margin-bottom: 10px;
    }
    p {
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 25px;
        line-height: 1.6em;
        color: #cdd0f8;
    }

    .reset {
        cursor: pointer;
    }
}