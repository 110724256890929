.payment-form-wrap {
    min-height: 380px;
    background-color: #9169d8;
    justify-content: center;
    display: flex;
    margin-top: 70px;
    width: 50%;
    align-items: center;
    border-radius: 4px;
    box-shadow: 0 7px 14px rgb(50 50 93 / 10%), 0 3px 6px rgb(0 0 0 / 8%);
    padding: 40px 0px;
    margin-left: -20px;
    margin-right: -20px;


    .card-element {
        color: #fff;
    }
    .group {
        border: 1px solid #b5a4ed;
        padding: 15px;
        border-radius: 6px;

        legend {
            margin: 0 auto;
            padding: 0 10px;
            text-align: center;
            font-size: 14px;
            font-weight: 500;
            color: #cdd0f8;
            background-color: #9169d8;
        }
    }

    .cell.example.example5{
        width: 70%;
    }
}

@media screen and (max-width: 992px) {
    .payment-form-wrap {
        width: 90%;
        .cell.example.example5 {
            padding: 0px !important;
        }
    }
  }