.wrapper {
    background-image: url('../../assets/images/VPbackground.png');

    .main {
        z-index: 9999;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 100px;
        .logo {
            img {
                width: 300px;
            }
            h1 {
                font-weight: 400;
                color: #525f7f;
                margin-top: 20px;
                font-size: 24px;
                line-height: 36px;
            }
            display: flex;
            flex-direction: column;
            align-items: center;
            padding-top: 100px;
        }
    }
}

.example.example5 {
    background-color: #9169d8;
  }
  
  .example.example5 * {
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
    font-size: 16px;
    font-weight: 400;
  }
  
  .example.example5 form {
  }
  
  #example5-paymentRequest {
    max-width: 500px;
    width: 100%;
    margin-bottom: 10px;
  }
  
  .example.example5 fieldset {
    border: 1px solid #b5a4ed;
    padding: 15px;
    border-radius: 6px;
  }
  
  .example.example5 fieldset legend {
    margin: 0 auto;
    padding: 0 10px;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    color: #cdd0f8;
    background-color: #9169d8;
  }
  
  .example.example5 fieldset legend + * {
    clear: both;
  }
  
  .example.example5 .card-only {
    display: block;
  }
  .example.example5 .payment-request-available {
    display: none;
  }
  
  .example.example5 .row {
    display: -ms-flexbox;
    display: flex;
    margin: 0 0 10px;
  }
  
  .example.example5 .field {
    position: relative;
    width: 100%;
  }
  
  .example.example5 .field + .field {
    margin-left: 10px;
  }
  
  .example.example5 label {
    width: 100%;
    color: #cdd0f8;
    font-size: 13px;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  
  .example.example5 .input {
    width: 100%;
    color: #fff;
    background: transparent;
    padding: 5px 0 6px 0;
    border-bottom: 1px solid #a988ec;
    transition: border-color 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  }
  
  .example.example5 .input::-webkit-input-placeholder {
    color: #bfaef6;
  }
  
  .example.example5 .input::-moz-placeholder {
    color: #bfaef6;
  }
  
  .example.example5 .input:-ms-input-placeholder {
    color: #bfaef6;
  }
  
  .example.example5 .input.StripeElement--focus,
  .example.example5 .input:focus {
    border-color: #fff;
  }
  .example.example5 .input.StripeElement--invalid {
    border-color: #ffc7ee;
  }
  
  .example.example5 input:-webkit-autofill,
  .example.example5 select:-webkit-autofill {
    -webkit-text-fill-color: #fce883;
    transition: background-color 100000000s;
    -webkit-animation: 1ms void-animation-out;
  }
  
  .example.example5 .StripeElement--webkit-autofill {
    background: transparent !important;
  }
  
  .example.example5 input,
  .example.example5 button,
  .example.example5 select {
    -webkit-animation: 1ms void-animation-out;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
    border-style: none;
    border-radius: 0;
  }
  
  .example.example5 select.input,
  .example.example5 select:-webkit-autofill {
    background-image: url('data:image/svg+xml;utf8,<svg width="10px" height="5px" viewBox="0 0 10 5" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><path fill="#fff" d="M5.35355339,4.64644661 L9.14644661,0.853553391 L9.14644661,0.853553391 C9.34170876,0.658291245 9.34170876,0.341708755 9.14644661,0.146446609 C9.05267842,0.0526784202 8.92550146,-2.43597394e-17 8.79289322,0 L1.20710678,0 L1.20710678,0 C0.930964406,5.07265313e-17 0.707106781,0.223857625 0.707106781,0.5 C0.707106781,0.632608245 0.759785201,0.759785201 0.853553391,0.853553391 L4.64644661,4.64644661 L4.64644661,4.64644661 C4.84170876,4.84170876 5.15829124,4.84170876 5.35355339,4.64644661 Z" id="shape"></path></svg>');
    background-position: 100%;
    background-size: 10px 5px;
    background-repeat: no-repeat;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 20px;
  }
  
  .example.example5 button {
    display: block;
    width: 100%;
    height: 40px;
    margin: 20px 0 0;
    background-color: #fff;
    border-radius: 6px;
    color: #9169d8;
    font-weight: 500;
    cursor: pointer;
  }
  
  .example.example5 button:active {
    background-color: #cdd0f8;
  }
  
  .example.example5 .error svg .base {
    fill: #fff;
  }
  
  .example.example5 .error svg .glyph {
    fill: #9169d8;
  }
  
  .example.example5 .error .message {
    color: #fff;
  }
  
  .example.example5 .success .icon .border {
    stroke: #bfaef6;
  }
  
  .example.example5 .success .icon .checkmark {
    stroke: #fff;
  }
  
  .example.example5 .success .title {
    color: #fff;
  }
  
  .example.example5 .success .message {
    color: #cdd0f8;
  }
  
  .example.example5 .success .reset path {
    fill: #fff;
  }
  
  @media (max-width: 540px) {
    .cell.example.example5{
      padding: 80px 15px;
    }
  }
  
  .vl {
    border-left: 1px solid #c9c9c9;
    height: 85%;
  }
  
  @media (max-width: 991.98px) and (min-width: 768px) {
    .line-vl {
      display: none;
    }
  }
  
  footer.vp-footer {
    background-color: white;
    max-width: none;
    font-size: 1.05rem;
    line-height: 2rem;
    padding-top: 4rem !important;
    background-image: url(../../assets/images/footershape.png);
    background-repeat: no-repeat;
    background-position: 34% 55%;
  }
  ul.unlisted-footer li a {
    color: #333;
  }
  
  ul.unlisted-footer {
    list-style: none;
    line-height: 2.5rem;
  }
  
  @import url('https://fonts.googleapis.com/css?family=Lato:100,300,400,700,900&display=swap');
  
  @import url('https://fonts.googleapis.com/css?family=Hind:300,400,500,600,700&display=swap');
  
  
  main > .container-lg .example.example5 {
    padding: 20px 20px 100px 20px;
    min-height: 380px;
  }
  
  .common-IntroText {
    text-align: center;
  }
  
  p,
  .p {
    font-family: 'Hind', sans-serif !important;
  }
  
  .vl {
    border-left: 1px solid #c9c9c9;
    height: 85%;
  }
  
  .centered-d-flex {
    align-items: center;
    justify-content: center;
  }
  
  ul.unlisted-footer {
    list-style: none;
    line-height: 2.5rem;
  }
  
  ul.unlisted-footer li a {
    color: #333;
  }
  
  ul.unlisted-footer li a:hover {
    color: #0070b8;
  }
  
  @media (max-width: 575.98px) {
  
  
    .mobile-inline-padding {
      padding-left: unset !important;
    }
  
    .mobile-center-left {
      text-align: left !important;
    }
  
    .mobile-centered-flex {
      justify-content: center !important;
    }
  
  
    .column-1,
    .column-2,
    .column-3 {
      text-align: center !important;
    }
  
    .vp-footer {
      background-position: -3% 30% !important;
      background-size: 45%;
    }
  
    ul.arrow-list {
      padding-left: 20px;
    }
  
    h5.panel-title {
      padding-right: 40px;
      line-height: 28px;
    }
  
    .panel-heading[data-toggle="collapse"]:after {
      right: 0px !important;
    }
  
  }
  
  @media (min-width: 576px) and (max-width: 991.98px) {
    .line-vl {
      display: none;
    }
  
    .column-1,
    .column-2,
    .column-3 {
      text-align: center !important;
    }
  }
  
  @media (max-width: 991px) and (min-width: 768px) {
    .column-1, .column-2, .column-3 {
      text-align: center !important;
    }
  }
  
  @media (max-width: 767.98px) and (min-width: 576px) {
    .mobile-centered-flex {
      align-items: center !important;
      justify-content: center !important;
    }
  }
  
  main > .container-lg .example.submitted form, main > .container-lg .example.submitting form {
    height: 0;
  }

  