.stripes {
    position: absolute;
    width: 100%;
    transform: skewY(-12deg);
    height: 950px;
    top: -350px;
    background: linear-gradient(180deg, #e6ebf1 350px, rgba(230, 235, 241, 0));
  }
  
  .stripes .stripe {
    position: absolute;
    height: 190px;
  }
  
  .stripes .s1 {
    height: 380px;
    top: 0;
    left: 0;
    width: 24%;
    background: linear-gradient(90deg, #e6ebf1, rgba(230, 235, 241, 0));
  }
  
  .stripes .s2 {
    top: 380px;
    left: 4%;
    width: 35%;
    background: linear-gradient(
      90deg,
      hsla(0, 0%, 100%, 0.65),
      hsla(0, 0%, 100%, 0)
    );
  }
  
  .stripes .s3 {
    top: 380px;
    right: 0;
    width: 38%;
    background: linear-gradient(90deg, #e4e9f0, rgba(228, 233, 240, 0));
  }